import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';
import linkResolver from './src/utils/linkResolver';


if (process.env.NODE_ENV === 'production') {
  const ignoredWarnings = [
    'Minified React error',
  ];

  console.error = (...args) => {
    const [message] = args;
    if (typeof message === 'string' && ignoredWarnings.some(warning => message.includes(warning))) {
      return;
    }
  };
}

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME ?? '',
        linkResolver,
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
